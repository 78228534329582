// @ts-check
import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { graphql } from 'gatsby'

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <Helmet
        title={post.frontmatter.title}
        meta={[
          {
            name: 'description',
            content: `AngelEye Health's Policies & Procedures: ${
              post.frontmatter.title
              }`,
          },
        ]}
      />
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: post.html }}
      />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
